import Layout from '../components/Layout'
import React from 'react'
import SectionPress from '../components/SectionPress'
import Seo from '../components/Seo'
import fr from '../locales/fr'
import { graphql } from 'gatsby'

interface PressePageProps {
  data: GatsbyTypes.PressePageQuery
}

const PressePage: React.FunctionComponent<PressePageProps> = ({ data }) => (
  <Layout>
    <Seo title={fr.press} description={fr.pressMetaDesc} />
    <SectionPress presses={data.posts.nodes} />
  </Layout>
)

export default PressePage

export const pageQuery = graphql`
  query PressePage {
    posts: allWpPost(
      sort: { fields: [date], order: DESC }
      filter: { categories: { nodes: { elemMatch: { slug: { eq: "presse" } } } } }
    ) {
      nodes {
        ...Post
      }
    }
  }
`
